import React, { useEffect, useState } from "react";
import Select from 'react-select';

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '45px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
      boxShadow: state.isFocused ? null : null,
      fontSize: '13px',
      borderColor: "#696969",
      borderWidth: '1px',
    }),
    menu: provided => ({ ...provided, zIndex: 2 })
}

export const InputSelect = ({label, value, options, change, isMulti, isDisabled}) => {
    const [option, setOption] = useState(null);

    useEffect(() => {
        if(!isMulti){
            let arr = options ? options.filter(opt => opt.value === value) : [];
            setOption(arr[0]);
        } else {
            let arr = options ? options.filter(opt => value.includes(opt.value)) : [];
            setOption(arr);
        }
    }, [value]);

    const handleOnChange = value => {
        if(!isMulti){
            change(value.value);
            return;
        } else {
            let values = [];
            value.map(option => values.push(option.value));
            change(values);
        }
    }

    return (
        <div className="select-group">
            <label>{label}</label>
            <Select 
                isMulti={isMulti ? true : false}
                isDisabled={isDisabled ? true : false}
                placeholder="Selecione..."
                value={Array.isArray(option) ? option :  options.filter(function(option) {
                    return option.value === value;
                })}          
                options={options ? options : []} 
                onChange={handleOnChange}
                styles={customStyles}
            />
        </div>
    );
}

export default InputSelect;
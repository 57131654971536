import React, { useEffect, useState } from "react";
import Select from 'react-select';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";

export const ProductList = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');
    const [data, setData] = useState({header: [], rows: []});
    const [products, setProducts] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        getProducts();
    }, []);

    useEffect(() => {
        if(filters) getProducts();
    }, [filters]);

    useEffect(() => {
        generateData();
    }, [products]);

    const getProducts = () => {
        setProducts([]);
        setLoading(true);

        api.get(`/product${filters}`).then(res => {
            setProducts(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Código', 'SKU', 'Imagem', 'Nome', 'Categoria', 'Última atualização', 'Cadastrado em'];
        const rows = [];

        products.map(product => {
            let image =  <img src={ require(`../../assets/images/svgs/default.svg`).default } />;
            if(product.images.length > 0){
                image = <img src={product.images[0].image_url.small} />;
            }
            rows.push({
                data: [
                    product.code,
                    product.sku,
                    image,
                    product.name,
                    product.categories.length > 0 ? product.categories[0].name : '',
                    moment(product.updated_at).format('DD/MM/YYYY HH:mm'),
                    moment(product.created_at).format('DD/MM/YYYY HH:mm')
                ],
                to: `/product/edit/${product.id}`
            })
        });

        setData({header, rows});
    }
    

    return (
        <>
            <PanelTemplate id="product-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome', key: 'name', type: 'text' },
                                {name: 'SKU', key: 'sku', type: 'text' },
                                {name: 'Código', key: 'code', type: 'text' }
                            ]}
                            filter={setFilters}
                        />
                    }

                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar produto`} svg="plus-white" action={() => navigate(`/product/create`)}  />
                            {/* <Button type={`secondary`} size={`small`} text={`Filtrar`} svg="filter"  /> */}
                        </>
                    }
                />
                
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default ProductList;
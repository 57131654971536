export const isAuthenticated = () => localStorage.getItem('token') !== null;
export const getToken = () => localStorage.getItem('token');
export const setToken = token => localStorage.setItem('token', token);
export const setUser = user => localStorage.setItem('user', JSON.stringify(user) );
export const getUser = () => JSON.parse( localStorage.getItem('user') );
export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
};

export default isAuthenticated;
import React, { useEffect } from "react";

export const Button = ({type, svg, text, action, size, full, loading}) => {
    let className = `button ${type ? type : 'primary'}`;
    className += size ? ` ${size}` : ' regular';

    if(svg){
        className += ' has-svg';
    }

    if(full){
        className += ' full';
    }

    return (
        <button className={className} onClick={() => action()} disabled={loading}>
            {loading ? 'Carregando...' : text}
            {svg &&
                <img src={ require(`../../assets/images/svgs/${svg}.svg`) } alt="text" />
            }
        </button>
    );
}

export default Button;
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Echo from "laravel-echo";

import { useLocation } from "react-router-dom";
import { getUser, logout } from "../../services/auth";
import Button from "../Button";
import api from "../../services/api";

export const SidebarItem = ({item, user}) => {
    const [active, setActive] = useState(false);

    const location = useLocation();
    const pathname = location.pathname;
    
    useEffect(() => {
        if(pathname == item.to){
            setActive(true);
        }
        if(item.subitems){
            item.subitems.map(subitem => {
                if(subitem.to === pathname){
                    setActive(true);
                }
            });
        }
    }, [location]);

    return (
        <li className={active ? 'active' : ''}>
            {!item.subitems && 
                <Link to={item.to}>
                    <img src={ require(`../../assets/images/svgs/${item.svg}.svg`)} alt={item.name} />
                    {item.name}
                    {item?.alert !== undefined && item?.alert !== 0 &&
                        <span className="alert">{item.alert}</span>
                    }
                </Link>
            }
            {item.subitems && 
                <a onClick={() => setActive(prev => !prev)}>
                    <img src={ require(`../../assets/images/svgs/${item.svg}.svg`)} alt={item.name} />
                    {item.name}
                    {item.subitems &&
                        <img src={ require(`../../assets/images/svgs/arrow.svg`).default} className="arrow" alt="alterar" />
                    }
                </a>
            }
            
            {item.subitems &&
                <ul>
                    {item.subitems.map((subitem, index) => (
                        <SidebarSubitem subitem={subitem} key={index} />
                    ))}
                </ul>
            }
        </li>
    );
}

export const SidebarSubitem = ({subitem}) => {
    const [active, setActive] = useState(false);
    const [quoteCount, setQuoteCount] = useState(0);

    const location = useLocation();
    
    useEffect(() => {
        if(location.pathname === subitem.to){
            setActive(true);
        }
    }, []);

    return (
        <li className={active ? 'active' : ''}>
            <Link to={subitem.to}>
                <span className="dot"></span>
                {subitem.name}
            </Link>
        </li>
    );
}


export const Sidebar = () => {
    const user = getUser();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);

    useEffect(() => {
        getItems();
    }, []);

    const signOut = () => {
        logout();
        navigate(`/login`);
    }

    const getItems = () => {
        const base = [
            // {
            //     name: 'Dashboard',
            //     svg: 'dashboard',
            //     to: '/dashboard',
            // },
            {
                name: 'Produtos',
                svg: 'product',
                subitems: [
                    { name: 'Produtos', to: '/product/list' },
                    { name: 'Categorias', to: '/category/list' }
                ],
            },
            { name: 'Usuários', svg: 'user', to: '/user/list' },
            // {
            //     name: 'Configurações',
            //     svg: 'config',
            //     subitems: [
            //         { name: 'Dados de contato', to: '/params/list' },
            //         { name: 'Templates de e-mail', to: '/email-template/list' }
            //     ]
            // },
        ];

        setItems(base);
    }

    
    return (
        <aside id="sidebar">
            <ul>
                {items.map((item, index) => (
                    <SidebarItem item={item} key={index} user={user} />
                ))}
            </ul>
            <div id="user">
                <Link to="/">{user.name}</Link>
                <Button type={`transparent`} svg={`logout`} action={signOut} />
            </div>
        </aside>
    );
}

export default Sidebar;
export const createOptions = (array, label = null, value = null, nullable = false) => {
    let options = [];

    if(nullable){
        options.push({
            value: null,
            label: 'Nenhuma opção'
        });
    }

    array.map(item => {
        let hasItem = options.filter(o => o.value === item[value]).length === 0 ? false : true;
        if(!hasItem){
            options.push({
                label: label === null ? item : item[label],
                value: value === null ? item : item[value]
            })
        }
    });

    return options;
}

export const createProductOptions = (array, label = null, value = null, nullable = false) => {
    let options = [];

    if(nullable){
        options.push({
            value: null,
            label: 'Nenhuma opção'
        });
    }

    array.map(item => {
        let hasItem = options.filter(o => o.value === item[value]).length === 0 ? false : true;
        if(!hasItem){
            let labelImage = item.images.length > 0 ? <img src={item.images[0].image_url.small} /> :<img src={ require(`../assets/images/svgs/default.svg`).default } />;
            let labelText = label === null ? item : item[label];
            options.push({
                label: <div className="select-label">{labelImage}{labelText}</div>,
                value: value === null ? item : item[value]
            })
        }
    });

    return options;
}
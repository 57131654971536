import React from "react";

export const InputTextarea = ({label, placeholder, rows, fullHeight, value, change}) => {
    return (
        <div className={`input-textarea-group ${fullHeight ? 'full-height' : ''}`}>
            {label &&
                <label>{label}</label>
            }
            <textarea rows={rows} placeholder={placeholder ?? ''} onChange={e => change(e.target.value)} value={value} />
        </div>
    );
}

export default InputTextarea;
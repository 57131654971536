import React from 'react';
import { BrowserRouter, Navigate, Route, Routes as ReactRouterRoutes, useLocation } from "react-router-dom";

import isAuthenticated from '../services/auth';

import Login from '../pages/Login';
import PasswordRecovery from '../pages/PasswordRecovery';
import ProductList from '../pages/Product/list';
import ProductEdit from '../pages/Product/edit';
import ProductCreate from '../pages/Product/create';
import CategoryList from '../pages/Category/list';
import UserList from '../pages/User/list';

const Routes = () => {
    const RequireAuth = ({children}) => {
        let location = useLocation();
        
        if(!isAuthenticated()){
            return <Navigate to="/login" state={{ from: location }} />;
        }

        return children;
    };


    return (
        <BrowserRouter>
            <ReactRouterRoutes>
                <Route exact path="/" element={isAuthenticated ? <Navigate to="/product/list" /> : <Navigate to="/login" /> } />
                <Route path="/login" element={<Login />} />
                <Route path="/password-recovery" element={<PasswordRecovery />} />

                <Route path="/product/list" element={<RequireAuth><ProductList /></RequireAuth>} />
                <Route path="/product/edit/:id" element={<RequireAuth><ProductEdit /></RequireAuth>} />
                <Route path="/product/create" element={<RequireAuth><ProductCreate /></RequireAuth>} />

                <Route path="/category/list" element={<RequireAuth><CategoryList /></RequireAuth>} />

                <Route path="/user/list" element={<RequireAuth><UserList /></RequireAuth>} />
            </ReactRouterRoutes>
        </BrowserRouter>
    );
}

export default Routes;
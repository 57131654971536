import { toast } from "react-toastify";

export const renderError = error => {
    if(!error.response){
        toast.error('Erro no servidor');
        return;
    }

    if(error.response?.data?.fields){
        Object.keys(error.response.data.fields).map(function(field) {
            error.response.data.fields[field].map(err => {
                toast.error(err);
            });
        });
        return;
    }

    if(error?.response?.data?.message){
        toast.error(error.response.data.message);
        return;
    }

    toast.error('Erro desconhecido');
    return;
}
import React, { useState } from "react";
import Input from "../Input";
import InputCheckboxes from "../InputCheckboxes";
import InputEditor from "../InputEditor";
import InputRadio from "../InputRadio";
import InputSelect from "../InputSelect";

export const BoxTabs = ({className, titles, contents}) => {
    const [current, setCurrent] = useState(0);

    return (
        <div className={`box-tabs ${className ?? ''}`}>
            <div className="tabs">
                {titles.map((title, index) => (
                    <button
                        key={index}
                        className={index === current ? 'active' : ''}
                        onClick={() => setCurrent(index)}
                    >
                        {title}
                    </button>
                ))}
            </div>
            <div className="body">
                {contents[current]}
            </div>
        </div>
    );
}

export default BoxTabs;
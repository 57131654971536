import { values } from "draft-js/lib/DefaultDraftBlockRenderMap";

export const convertToSlug = str => {
    return str.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
}

export const convertToCnpj = str => {
    return str
        .replace(/\D+/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
}

export const convertToCpf = str => {
    return str
        .replace(/\D+/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
}

export const convertToCep = str => {
    return str
        .replace(/\D+/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1');
}

export const convertToCellphone = str => {
    return str
        .replace(/\D/g, '')
        .replace(/\D/g, '')
        .replace(/(\d{1})(\d)/, '($1$2')
        .replace(/(\d{2})(\d)/, '$1) $2')
        .replace( /(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
}

export const convertToPhone = str => {
    if(str.length >= 15){
        return str
            .replace(/\D/g, '')
            .replace(/\D/g, '')
            .replace(/(\d{1})(\d)/, '($1$2')
            .replace(/(\d{2})(\d)/, '$1) $2')
            .replace( /(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1');
    } else {
        return str
            .replace(/\D/g, '')
            .replace(/\D/g, '')
            .replace(/(\d{1})(\d)/, '($1$2')
            .replace(/(\d{2})(\d)/, '$1) $2')
            .replace( /(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1');
    }
}

export const convertToMoney = str => {
    let v = str.replace(/\D/g,'');
	v = (v/100).toFixed(2) + '';
	v = v.replace(".", ",");
	v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
	v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
	return `R$ ${v}`;
}

export const convertToPercentage = str => {
    let v = str.replace(/\D/g,'');
	v = (v/10000).toFixed(4) + '';
    v = v.replace(".", ",");
	return `${v} %`;
}

export const convertToInt = str => {
    let v = str.replace(/\D/g,'');
    v = parseInt(v);
    v = isNaN(v) ? 0 : v;
    return v;
}


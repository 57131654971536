import React, { useEffect, useState } from "react";
import Select from "react-select";

import { createOptions } from '../../helpers/selects';

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '35px',
      height: '35px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
      boxShadow: state.isFocused ? null : null,
      fontSize: '14px'
    }),
}

export const FilterSimple = ({params, filter}) => {
    const options = createOptions(params, 'name', 'key');

    const [option, setOption] = useState(options[0]);
    const [type, setType] = useState('');
    const [value, setValue] = useState('');

    useEffect(() => {
        let current = params.filter(param => param.key === option.value);
        setType(current[0].type);
    }, [option]);

    // useEffect(() => {
    //     if(value) filter(`?${option.value}[contain]=${value}`);
    // }, [value]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            filter(`?${option.value}[contain]=${value}`);
        }
    }

    return (
        <div className="filter-simple">
            {type === 'text' &&
                <input 
                    type={`text`} 
                    placeholder="Buscar" 
                    value={value} 
                    onChange={e => setValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
            }
            <Select options={options} value={option} onChange={setOption} styles={customStyles} />
        </div>
    );
}

export default FilterSimple;